import React from "react"

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Jumbotron from 'react-bootstrap/Jumbotron'

import Layout from "../components/layout"
import image from "../images/etc.png"
import SEO from "../components/seo"

import downloadFile from '../downloads/il.pdf'

import 'bootstrap/dist/css/bootstrap.min.css';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Container>
      <Row style={{'marginTop': '1rem'}}>
        <Col style={{'display': 'flex', 'alignItems': 'center'}}>
          <h1>ETC</h1>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Empresa de terminais marítimos</span>
        </Col>

        <Col className='button-col'>
          <Button href={downloadFile} variant="info">Informações ao público</Button>
        </Col>
      </Row>

      <Row>
        <img src={image} class='image' />
      </Row>

      <Row s={2}>
        <Col>
          <ListGroup>
            <ListGroup.Item>Morada: Porto Brandão, 2825-109 Caparica</ListGroup.Item>
            <ListGroup.Item>Email: geral@etcsa.pt</ListGroup.Item>
            <ListGroup.Item>Telefone: (+351) 212 948 930</ListGroup.Item>
          </ListGroup>
        </Col>

        <Col style={{'flex-grow': 0}}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m13!1m11!1m3!1d2847.5422392473215!2d-9.21278603191619!3d38.67589979213831!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1spt-PT!2sus!4v1457435297127"
            width="400" height="400" frameborder="0" allowfullscreen>
          </iframe>
        </Col>

      </Row>
    </Container>
  </Layout>
)

export default IndexPage
